.App {
    padding: 20px;
}

.Modal {
    display: flex;
    z-index: 2;
    outline: none;
    animation: popIn 0.2s;
    animation-timing-function: ease-out;
    max-height: 100%;
    max-width: calc(100% - 40px);
    padding: 10px;
}

.Overlay {
    display: flex;
    position: fixed;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8);
    animation: fadeIn 0.3s;
}

@keyframes fadeIn {
    from {
        background-color: rgba(0, 0, 0, 0);
    }
    to {
        background-color: rgba(0, 0, 0, 0.8);
    }
}

@keyframes popIn {
    from {
        transform: scale(1.1);
    }
    to {
        transform: scale(1);
    }
}
