@font-face {
    font-family: 'centra_no2bold';
    src: url('assets/fonts/centra_no2_bold-webfont.woff2') format('woff2'), url('assets/fonts/centra_no2_bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'centra_no2book';
    src: url('assets/fonts/centra_no2-webfont.woff2') format('woff2'), url('assets/fonts/centra_no2-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
html,
body,
#root {
    width: 100%;
    margin: 0;
    height: 100%;
    padding: 0;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    font-family: 'centra_no2book', sans-serif;
    background-color: black;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'centra_no2bold';
    font-weight: normal;
    margin: 0;
    padding: 0;
}
